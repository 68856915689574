import React, { useState, useRef } from "react";

import { Typography, Button } from "@material-ui/core";
import MaterialTable from "material-table";

import GoogleMapReact from "google-map-react";

import moment from "moment";

import CustomAlert from "components/CustomAlert";
import Marker from "components/Marker";

import axios from "config/axios";

import useStyles from "./styles";
//import { ArrowBack } from "@material-ui/icons";

const Boards: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const alertRef = useRef<any>();
  const tableRef = useRef<any>();

  const classes = useStyles();

  const getStatus = async () => {
    setLoading((oldState) => !oldState);
    axios
      .put("/adm/boards/get/status")
      .then(() => {
        setLoading((oldState) => !oldState);
        alertRef?.current.alterAlert("Get status enviado", "success");
        setTimeout(() => {
          tableRef.current && tableRef?.current?.onQueryChange();
        }, 500);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        alertRef?.current.alterAlert("Problema ao pedir Get status", "error");
      });
  };

  return (
    <>
      <CustomAlert ref={alertRef} />
      <Typography variant="h5" className={classes.title}>
        Placas
      </Typography>
      <div className={classes.center} style={{ marginBottom: 10 }}>
        <Button
          variant="contained"
          onClick={getStatus}
          color="secondary"
          disabled={loading}
        >
          Get Status
        </Button>
      </div>
      <MaterialTable
        options={{ exportButton: true, grouping: true, search: true }}
        tableRef={tableRef}
        detailPanel={[
          {
            icon: "memory",
            tooltip: "Info da placa",
            render: (rowData: any) => (
              <table className={classes.detailInfo}>
                <thead>
                  <tr>
                    <th>Serial</th>
                    <th>Wifi</th>
                    <th>Extra</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Typography variant="body1">
                        <b>{rowData.serial}</b>
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body1">
                        Rede: <b>{rowData.wifi ? rowData.wifi : "-"}</b>
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body1">
                        V. Dados:{" "}
                        <b>
                          {rowData?.lastAlive?.V_DATA
                            ? rowData?.lastAlive?.V_DATA
                            : "-"}
                        </b>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <Typography variant="body1">
                        RSSI:{" "}
                        <b>
                          {rowData?.lastAlive?.WiFi_RSSI
                            ? rowData?.lastAlive?.WiFi_RSSI
                            : "-"}
                        </b>
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body1">
                        Cont. seg.:{" "}
                        <b>
                          {rowData?.lastAlive?.Conta_seg
                            ? rowData?.lastAlive?.Conta_seg
                            : "-"}
                        </b>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <Typography variant="body1">
                        ResetReason:{" "}
                        <b>
                          {rowData?.lastAlive?.ResetReason
                            ? rowData?.lastAlive?.ResetReason
                            : "-"}
                        </b>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <Typography variant="body1">
                        Timestamp:{" "}
                        <b>
                          {rowData?.lastAlive?.Timestamp
                            ? moment(rowData?.lastAlive?.Timestamp).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : "-"}
                        </b>
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            ),
          },
          {
            icon: "map",
            tooltip: "Localização",
            render: (rowData: any) => (
              <div style={{ height: "55vh", width: "100%" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_KEY || "",
                  }}
                  defaultCenter={{ lat: rowData.lat, lng: rowData.long }}
                  defaultZoom={11}
                >
                  <Marker
                    lat={rowData.lat}
                    lng={rowData.long}
                    text={rowData.name}
                  />
                </GoogleMapReact>
              </div>
            ),
          },
        ]}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recarregar dados",
            isFreeAction: true,
            onClick: () =>
              tableRef.current && tableRef?.current?.onQueryChange(),
          },
        ]}
        style={{ fontFamily: "Nunito Sans" }}
        columns={[
          {
            title: "Placa",
            field: "name",
            type: "string",
          },
          {
            title: "Local",
            field: "place.name",
            type: "string",
          },
          {
            title: "MAC",
            field: "mac",
            type: "string",
          },
          {
            title: "FW",
            field: "lastAlive.V_FW",
            type: "string",
          },
          {
            title: "lat",
            field: "long",
            type: "numeric",
            hidden: true,
          },
          {
            title: "long",
            field: "lat",
            type: "numeric",
            hidden: true,
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let { page, pageSize, orderBy, orderDirection, search } = query;
            axios
              .post(`/adm/boards`, {
                page,
                pageSize,
                orderBy,
                orderDirection,
                search,
              })
              .then(({ data }) => {
                console.log(data);
                resolve({
                  data: data.docs,
                  page: data.page - 1,
                  totalCount: data.totalDocs,
                });
              })
              .catch((error) => {
                switch (error.response.data.code) {
                  default:
                    alertRef?.current.alterAlert(
                      "Problema ao carregar dados",
                      "error"
                    );
                    break;
                }
                reject("Problema ao carregar dados");
              });
          })
        }
        localization={{
          header: {
            actions: "Ações",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira",
            firstTooltip: "Primeira",
            previousAriaLabel: "Anterior",
            previousTooltip: "Anterior",
            nextAriaLabel: "Próxima",
            nextTooltip: "Próxima",
            lastAriaLabel: "Última",
            lastTooltip: "Última",
          },
          toolbar: {
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem informações",
          },
          grouping: {
            groupedBy: "Agrupado por:",
            placeholder: "Arraste um cabeçalho para agrupar",
          },
        }}
        title="Histórico de uso"
      />
    </>
  );
};

export default Boards;
