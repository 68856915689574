import React from "react";
import useStyles from "./styles";
import "./Marker.css";

import marker from "assets/img/markers/marker.png";

export default function Marker(props: any) {
  const classes = useStyles();
  return (
    <div className={classes.markerDiv}>
      <img src={marker} className="marker" alt="Marcador" />
    </div>
  );
}
