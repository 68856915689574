import { Routes } from "types";
import { Boards } from "views/dashboard";

import { Memory } from "@material-ui/icons";

export const adminRoutes: Routes = [
  {
    path: "/boards",
    name: "Placas",
    icon: Memory,
    component: Boards,
    layout: "/dashboard",
  },
  /*  {
      path: "/list/deliveries",
      name: "Suas entregas",
      //rtlName: "لوحة القيادة",
      icon: "inventory_2",
      component: ListDeliveries,
      layout: "/admin",
    }, */
];
