import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    tabsContainer: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      marginBottom: 10,
    },
    backArrowBtn: {
      backgroundColor: "white",
      marginBottom: 10,
    },
    center: {
      textAlign: "center",
      justifyContent: "center",
    },
    detailInfo: {
      padding: "3%",
      flex: 1,
      width: "100%",
    },
  })
);
export default useStyles;
